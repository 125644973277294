import Vue from "vue";
import Router from "vue-router";

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {});
};

Vue.use(Router);

const constantRoutes = [
    {
        path: "/loading",
        name: "loading",
        component: () => import("@/views/loading.vue"),
    },
    {
        path: "/",
        name: "home",
        component: () => import("@/views/Home/index.vue"),
    },
    {
        path: "/AboutUs",
        name: "AboutUs",
        component: () => import("@/views/Home/AboutUs.vue"),
    },
    // {
    //     path: "/Partner",
    //     name: "Partner",
    //     component: () => import("@/views/Home/Partner.vue"),
    // },
    {
        path: "/Collection",
        name: "Collection",
        component: () => import("@/views/Home/Collection.vue"),
    },
    {
        path: "/Payment",
        name: "Payment",
        component: () => import("@/views/Home/Payment.vue"),
    },
    {
        path: "/Swap",
        name: "Swap",
        component: () => import("@/views/Home/Swap.vue"),
    },
    {
        path: "/CrossBorder",
        name: "CrossBorder",
        component: () => import("@/views/Home/CrossBorder.vue"),
    },
    {
        path: "/ForeignTrade",
        name: "ForeignTrade",
        component: () => import("@/views/Home/ForeignTrade.vue"),
    },
];

const createRouter = () =>
    new Router({
        mode: "history",
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRoutes,
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;
